import React, { useState, useEffect } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import './App.css';

function App() {
  const mapdata = {
    coords: {lat: 36.727419229793, lng: -2.8402375785170437},
    zoom: 17,
  };

  const data = {
    es: {
      crop: 'Pimiento',
      types: ['verde', 'rojo', 'pinton', 'amarillo'],
      curr: 'Producción actual',
      forecast: 'Previsión (1 semana)',
      green: 'invernadero',
      harvest: 'Crear orden de recolección',
      tasks: 'Tareas pendientes',
      start: 'Iniciar',
      all: 'todos',
      goback: 'Volver',
      download: 'Descargar orden',
      order: 'Orden actual',
      list: 'Sectores'
    },

    en: {
      crop: 'Pepper',
      types: ['green', 'red', 'garnet', 'yellow'],
      curr: 'Current production',
      forecast: '1-week forecast',
      green: 'greenhouse',
      harvest: 'Create harvest task',
      tasks: 'Pending tasks',
      start: 'Start',
      all: 'all',
      goback: 'Go back',
      download: 'Download order',
      order: 'Current order',
      list: 'Sectors'
    },

    greenhouses : [
      {
        name: 'Alpha',
        production: [500,100,50,30],
        shape: [
          { lat: 36.727651342953784, lng: -2.8413366278922343 },
          { lat: 36.72712822396988, lng: -2.841125311534484 },
          { lat: 36.7272192221205, lng: -2.840443983390882 },
          { lat: 36.72780885978646, lng: -2.840534335089794 }
        ],
        markerPos: { lat: 36.72745946380069, lng: -2.84088511855611}
      },
      {
        name: 'Beta',
        production: [100,300,80,60],
        shape: [
          { lat: 36.72790454183924, lng: -2.840302224232623 },
          { lat: 36.726972385822734, lng: -2.840142520505269 },
          { lat: 36.726998236607976, lng: -2.839633761838283 },
          { lat: 36.72754136809741, lng: -2.8397157397490718 },
          { lat: 36.72764421965341, lng: -2.8389764251649887 },
          { lat: 36.72803549815197, lng: -2.8390170125373064 }
        ],
        markerPos: { lat: 36.7276885594914, lng: -2.839741368739358}
      },
      {
        name: 'Gamma',
        production: [200,100,100,250],
        shape: [
          { lat: 36.72672423362833, lng: -2.840312715873502 },
          { lat: 36.72623668154929, lng: -2.840195212344202 },
          { lat: 36.72636756141349, lng: -2.8388817274530025 },
          { lat: 36.726893974882486, lng: -2.839034036040041 }
        ],
        markerPos: { lat: 36.72663307190626, lng: -2.83964999811412 }
      }
    ]
  }

  const [step, setStep] = useState(0);
  const [variety, setVariety] = useState(0);
  const [lang, setLang] = useState(data.es);
  const [harvest_values, setHarvest_values] = useState([0,0,0,0]);
  const [current_values, setCurrentValues] = useState([0,0,0,0]);
  const [forecast_values, setForecastValues] = useState([0,0,0,0]);
  const [current_total, setCurrentTotal] = useState(0);
  const [forecast_total, setForecastTotal] = useState(0);
  const [filter, setFilter] = useState(4);
  const [orderList, setOrderList] = useState();
  const [green, setGreen] = useState(0);

  let green_list = [0,0,0];

  const loader = new Loader({
    apiKey: "AIzaSyBelWwwf_FCgkrbiL6BJtb0CFwogpAqzaw",
    version: "weekly"
  });
  let map;

  const calculateValues = () => {
    let total = 0;
    let variety = [0, 0, 0, 0];
    let forecast = [0, 0, 0, 0];

    data.greenhouses.forEach(greenhouse => {
      for (let i = 0; i < 4; i++) {
        total += greenhouse.production[i];
        variety[i] += greenhouse.production[i];
      }
    });

    forecast[0] = Math.round((variety[0] - ((harvest_values[0]*variety[0]))/100) * 0.2 + 500);
    forecast[1] = Math.round(variety[1] - harvest_values[1] + (variety[0] - ((harvest_values[0]*variety[0]))/100) * 0.8);
    forecast[2] = Math.round((variety[2] * 1.2) - (harvest_values[2]*variety[2])/100);
    forecast[3] = Math.round((variety[3] * 1.6) - (harvest_values[3]*variety[3])/100);

    setCurrentTotal(total);
    
    total = 0;
    forecast.forEach((value, index) => {
      total += value;
    });

    setForecastTotal(total);

    setCurrentValues(variety);
    setForecastValues(forecast);
  };

  const next = (index=-1) => {
    if (step < 2) {
      if (step == 1) {
        setGreen(index);
      }
      setStep(step + 1)
    }
  };

  const prev = () => {
    if (step > 0) { 
      setStep(step - 1)
    }
  };

  const createTagContent = (index) => {
    let value = 0;
    if (filter == 4) {
      let total = 0;
      data.greenhouses[index].production.forEach(variety => {
        total += variety;
      });
      console.log(current_total);
      value = Math.round((total * 100)/current_total);
    }
    else {
      let total = 0;
      data.greenhouses.forEach(greenhouse => {total += greenhouse.production[filter]});

      value = Math.round((data.greenhouses[index].production[filter] * 100) / total);
    }

    green_list[index] = value;

    return `
      <div>
        <div class="green-tag-title">${data.greenhouses[index].name}</div>
        <div class="green-tag-value"><span class="fs-4">${value}</span><span class="small">%</span></div>
      </div>
    `;
  };

  const changeLang = (e) => {
    if (e.currentTarget.id == 'es') {
      setLang(data.es)
    } else {
      setLang(data.en)
    }
  };

  const changeVariety = (e) => {
    setVariety(e.currentTarget.id)
  };

  const changeFilter = (e) => {
    setFilter(e.currentTarget.id);
  };

  const refreshValues = (e) => {
    let new_values = [...harvest_values];
    new_values[variety] = e.currentTarget.value;
    setHarvest_values(new_values);
    calculateValues();
    //setFilter(new_values.indexOf(Math.max(...new_values)));
  };

  const createOrderedList = () => {
    let list = [];
    for (let i = 0; i < 3; i++) {
      list.push({name: data.greenhouses[i].name, value: green_list[i]});
    }

    return list.sort((a,b) => { return b.value - a.value });
  };

  const loadMap = () => {
    loader.load().then(async (google) => {
      const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');
      map = new google.maps.Map(document.getElementById("maptarget"), {
        mapId: 'bc75558978865315',
        center: mapdata.coords,
        zoom: mapdata.zoom,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        zoomControl: false
      });
      
      const tags = [null, null, null];
      // Construct polygons.
      data.greenhouses.forEach((greenhouse, index) => {
        const polygon = new google.maps.Polygon({
          paths: greenhouse.shape,
          strokeColor: "#00FF00",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#00FF00",
          fillOpacity: 0.35,
        });
        polygon.setMap(map);

        tags[index] = document.createElement('div');
        tags[index].id = `tag${index}`;
        tags[index].className = "green-tag anim";
        tags[index].innerHTML = createTagContent(index);
        const marker = new AdvancedMarkerElement({
          map,
          position: greenhouse.markerPos,
          content: tags[index],
        });

        marker.addListener("click", () => next(index));
      });

      console.log(green_list);
      console.log(green_list.indexOf(Math.max(...green_list)));
      tags[green_list.indexOf(Math.max(...green_list))].className = "green-tag-max anim";
      setOrderList(createOrderedList());
    });
  };

  useEffect(() => {
    calculateValues();
  }, []);

  useEffect(() => {
    if (step == 1) {
      loadMap();
    }
  }, [step, filter]);

  return (
    <div className="App">

      <div className="modal fade pt-5 px-2" id="tasksModal" tabIndex="-1" aria-labelledby="taskModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header bg-warning">
              <h1 className="modal-title fs-5" id="taskModalLabel">{lang.tasks}</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <ul className='list-group'>
                <li className='list-group-item text-start fw-bold'>
                  {new Date(new Date().setDate(new Date().getDate()-1)).toLocaleDateString()}
                  <i title={lang.download} role='button' className="bi bi-file-earmark-arrow-down float-end text-primary"></i>
                </li>
                <li className='list-group-item text-start fw-bold'>
                  {new Date(new Date().setDate(new Date().getDate()-2)).toLocaleDateString()}
                  <i title={lang.download} role='button' className="bi bi-file-earmark-arrow-down float-end text-primary"></i>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade pt-5 px-2" id="greenModal" tabIndex="-1" aria-labelledby="greenModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h1 className="modal-title fs-5" id="greenModalLabel">{lang.order}</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className='title'>{lang.crop}: {filter < 4 ? lang.types[filter] : lang.all}</div>
              <ul className='list-group'>
                { orderList &&
                  orderList.map((greenhouse, index) => {
                    return (
                      <li key={index} className='list-group-item text-start fw-bold'>
                        {lang.green.toUpperCase()}: {greenhouse.name}
                        <span className='float-end'>{greenhouse.value}<span className='small'>%</span></span>
                      </li>
                    );
                  })
                }
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade pt-5 px-2" id="sectorModal" tabIndex="-1" aria-labelledby="sectorModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h1 className="modal-title fs-5" id="sectorModalLabel">{lang.list}</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            { green == 0 &&
            <div className="modal-body">
              <div className='title'>{lang.crop} - {lang.green.toUpperCase()}: {data.greenhouses[green].name}</div>
              <ul className='list-group'>
                <li className='list-group-item text-start fw-bold'>{lang.types[0].toUpperCase()}: <span className='fw-normal'>1A, 1B, 1D, 2A, 2B, 3A, 3B, 4B, 4C, 4D, 6A, 6B, 6D, 9B</span></li>
                <li className='list-group-item text-start fw-bold'>{lang.types[1].toUpperCase()}: <span className='fw-normal'>5A, 5B, 7A, 7B, 9A, 10A, 10B</span></li>
                <li className='list-group-item text-start fw-bold'>{lang.types[2].toUpperCase()}: <span className='fw-normal'>7C, 7D</span></li>
                <li className='list-group-item text-start fw-bold'>{lang.types[3].toUpperCase()}: <span className='fw-normal'>9C, 9D, 10D</span></li>
              </ul>
            </div>
            }
            { green == 1 &&
            <div className="modal-body">
              <div className='title'>{lang.crop} - {lang.green.toUpperCase()}: {data.greenhouses[green].name}</div>
              <ul className='list-group'>
                <li className='list-group-item text-start fw-bold'>{lang.types[0].toUpperCase()}: <span className='fw-normal'>4A, 5A, 5B, 6A, 6B, 6D, 7B, 7C</span></li>
                <li className='list-group-item text-start fw-bold'>{lang.types[1].toUpperCase()}: <span className='fw-normal'>1A, 1B, 1C, 1D, 2A, 3A, 3B, 8D, 9C, 9D, 10A, 10B, 10C, 11A, 11B, 11D, 12A, 12B, 12D</span></li>
                <li className='list-group-item text-start fw-bold'>{lang.types[2].toUpperCase()}: <span className='fw-normal'>3D, 4C, 4D, 5C, 5D, 7D, 8A, 8B, 9A</span></li>
                <li className='list-group-item text-start fw-bold'>{lang.types[3].toUpperCase()}: <span className='fw-normal'>1F, 1G, 3E, 3F, 3G, 4G, 5E, 5F</span></li>
              </ul>
            </div>
            }
            { green == 2 &&
            <div className="modal-body">
              <div className='title'>{lang.crop} - {lang.green.toUpperCase()}: {data.greenhouses[green].name}</div>
              <ul className='list-group'>
                <li className='list-group-item text-start fw-bold'>{lang.types[0].toUpperCase()}: <span className='fw-normal'>1B, 1C, 3A, 3B, 3C, 4C, 6C</span></li>
                <li className='list-group-item text-start fw-bold'>{lang.types[1].toUpperCase()}: <span className='fw-normal'>5B, 5C. 10B, 10C, 12B, 12C, 13A, 13B, 13C, 14B, 14C</span></li>
                <li className='list-group-item text-start fw-bold'>{lang.types[2].toUpperCase()}: <span className='fw-normal'>5A, 6A, 6B, 7B, 7C, 9A, 9B, 9C, 11A, 11B, 11C</span></li>
                <li className='list-group-item text-start fw-bold'>{lang.types[3].toUpperCase()}: <span className='fw-normal'>1D, 1E, 1F, 3D, 3E, 3F, 4D, 4E, 4F, 5D, 5E, 7D, 7E, 7F, 11D, 11E, 11F, 12E, 12F, 13D, 13E</span></li>
              </ul>
            </div>
            }
          </div>
        </div>
      </div>

      <header>
        <div className='logo-box'><img className='logo' src='/GRODI_Color_200px.png'></img></div>
        <div className='logo-text'><span>OSIRIS</span></div>
        {/* <button onClick={prev}>atras</button>
        <button onClick={next}>adelante</button> */}
        <div className='lang-buttons'>
          <div className="btn-group btn-group-sm text-end p-3" role="group" aria-label="Basic radio toggle button group">
            <input type="radio" className="btn-check" name="btnradio" id="btnradio1" defaultChecked />
            <label id='es' className="btn btn-outline-warning" htmlFor="btnradio1" onClick={changeLang}>ES</label>

            <input type="radio" className="btn-check" name="btnradio" id="btnradio2" />
            <label id='en' className="btn btn-outline-warning" htmlFor="btnradio2" onClick={changeLang}>EN</label>
          </div>
        </div>
      </header>
      <div className='pagination pt-3'>
      { 
        <div id='summary' className={`container step maxw${step!=0?' hide':''}`}>
          <div className='row px-1'>
            <div id='production' className='col-md-6 mb-3'>
              <div className='panel'>
                <div className='crop'>{lang.crop.toUpperCase()}<span className='badge text-bg-success float-end'>{data.greenhouses.length} {lang.green}/s</span></div>
                <hr className='mt-0'/>
                <div className='title'>{lang.curr.toUpperCase()}</div>
                <div className='d-flex flex-row'>
                  <div className='col border-end px-1'>
                    <div className='variety green'>{lang.types[0].toUpperCase()}</div>
                    <div className='fs-3'><strong>{Math.round((current_values[0] / current_total) * 100)}</strong>%</div>
                  </div>
                  <div className='col border-end px-1'>
                    <div className='variety red'>{lang.types[1].toUpperCase()}</div>
                    <div className='fs-3'><strong>{Math.round((current_values[1] / current_total) * 100)}</strong>%</div>
                  </div>
                  <div className='col border-end px-1'>
                    <div className='variety garnet'>{lang.types[2].toUpperCase()}</div>
                    <div className='fs-3'><strong>{Math.round((current_values[2] / current_total) * 100)}</strong>%</div>
                  </div>
                  <div className='col px-1'>
                    <div className='variety yellow'>{lang.types[3].toUpperCase()}</div>
                    <div className='fs-3'><strong>{Math.round((current_values[3] / current_total) * 100)}</strong>%</div>
                  </div>
                </div>
                <hr />
                <div className='title'>{lang.forecast.toUpperCase()}</div>
                <div className='d-flex flex-row'>
                  <div className='col border-end px-1'>
                    <div className='variety green'>{lang.types[0].toUpperCase()}</div>
                    <div className='fs-3'><strong>{Math.round((forecast_values[0] / forecast_total) * 100)}</strong>%</div>
                  </div>
                  <div className='col border-end px-1'>
                    <div className='variety red'>{lang.types[1].toUpperCase()}</div>
                    <div className='fs-3'><strong>{Math.round((forecast_values[1] / forecast_total) * 100)}</strong>%</div>
                  </div>
                  <div className='col border-end px-1'>
                    <div className='variety garnet'>{lang.types[2].toUpperCase()}</div>
                    <div className='fs-3'><strong>{Math.round((forecast_values[2] / forecast_total) * 100)}</strong>%</div>
                  </div>
                  <div className='col px-1'>
                    <div className='variety yellow'>{lang.types[3].toUpperCase()}</div>
                    <div className='fs-3'><strong>{Math.round((forecast_values[3] / forecast_total) * 100)}</strong>%</div>
                  </div>
                </div>
              </div>
            </div>
            <div id='harvest' className='col-md-6 mb-4'>
              <div className='panel'>
                <div className='title'>{lang.harvest.toUpperCase()}</div>
                <div className='d-flex flex-row'>
                  <div id='0' role='button' className={`col anim border rounded p-2${variety == 0 ? ' selected' : ''}`} onClick={changeVariety}>
                    <div className='variety green'>{lang.types[0].toUpperCase()}</div>
                    <div className='fs-3'><strong>{harvest_values[0]}</strong>%</div>
                  </div>
                  <div id='1' role='button' className={`col anim border rounded p-2 ${variety == 1 ? ' selected' : ''}`} onClick={changeVariety}>
                    <div className='variety red'>{lang.types[1].toUpperCase()}</div>
                    <div className='fs-3'><strong>{harvest_values[1]}</strong>%</div>
                  </div>
                  <div id='2' role='button' className={`col anim border rounded p-2 ${variety == 2 ? ' selected' : ''}`} onClick={changeVariety}>
                    <div className='variety garnet'>{lang.types[2].toUpperCase()}</div>
                    <div className='fs-3'><strong>{harvest_values[2]}</strong>%</div>
                  </div>
                  <div id='3' role='button' className={`col anim border rounded p-2 ${variety == 3 ? ' selected' : ''}`} onClick={changeVariety}>
                    <div className='variety yellow'>{lang.types[3].toUpperCase()}</div>
                    <div className='fs-3'><strong>{harvest_values[3]}</strong>%</div>
                  </div>
                </div>
                <div className="range">
                  <input type="range" className="mt-2 w-100" id="customRange1" max="100" min="0" value={harvest_values[variety]} onChange={refreshValues}/>
                </div>
                <hr className='mt-1' />
                <div className='text-end'>
                  <button className='btn btn-warning float-start position-relative' data-bs-toggle='modal' data-bs-target='#tasksModal'>
                    {lang.tasks}
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">2</span>
                  </button>
                  <button className='btn btn-success mb-1' onClick={next}>{lang.start}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      { 
        <div id='map' className={`container step${step!=1?' hide':''}`}>
          {/* <iframe id='maploader' className={`map${step!=1?' d-none':''}`} src="https://www.google.com/maps/d/u/1/embed?mid=1SH4VQhzvUyhxC1Kmxbj6eBJfU8CULJA&ehbc=2E312F&noprof=1"></iframe> */}
          <div id='maptarget' className={`map${step != 1 ? ' d-none' : ''}`}></div>
          <div className='row '>
            <div id='harvest' className='col-md-6 mb-3'>
              <div className='panel text-start'>
                <span title={lang.goback} role='button' onClick={prev}><i className='bi bi-caret-left border-end pe-1'></i></span>
                <span className='crop'>{lang.crop.toUpperCase()}</span>
                <div className='d-flex flex-row gap-1 float-end pt-1'>
                  <div id='4' role='button' onClick={changeFilter} className={`anim variety all px-2 ${filter == 4 ?'filter':''}`} title={lang.all.toUpperCase()}>{lang.all.toUpperCase()}</div>
                  <div id='0' role='button' onClick={changeFilter} className={`anim variety green px-2 ${filter == 0 ?'filter':''}`} title={lang.types[0].toUpperCase()}>{lang.types[0].toUpperCase()[0]}</div>
                  <div id='1' role='button' onClick={changeFilter} className={`anim variety red px-2 ${filter == 1 ?'filter':''}`} title={lang.types[1].toUpperCase()}>{lang.types[1].toUpperCase()[0]}</div>
                  <div id='2' role='button' onClick={changeFilter} className={`anim variety garnet px-2 ${filter == 2 ?'filter':''}`} title={lang.types[2].toUpperCase()}>{lang.types[2].toUpperCase()[0]}</div>
                  <div id='3' role='button' onClick={changeFilter} className={`anim variety yellow px-2 ${filter == 3 ?'filter':''}`} title={lang.types[3].toUpperCase()}>{lang.types[3].toUpperCase()[0]}</div>
                </div>
              </div>
            </div>
            <div className='col-md-6 mb-3 text-start'>
              <button className='btn btn-warning small-shadow' data-bs-toggle='modal' data-bs-target='#tasksModal'>
                {lang.tasks}
                <span className="badge bg-danger ms-2 me-0">2</span>
              </button>
              <button className='btn btn-primary small-shadow ms-2' data-bs-toggle='modal' data-bs-target='#greenModal'>
                {lang.order}
                <i className="bi bi-file-earmark-arrow-down ms-2"></i>
              </button>
            </div>
          </div>
        </div>
      }

      { 
        <div id='green' className={`container step ${step!=2?' hide':''}`}>
          <div className='row'>
            <div id='harvest' className='col-md-6 mb-3'>
              <div className='panel text-start'>
                <span title={lang.goback} role='button' onClick={prev}><i className='bi bi-caret-left border-end pe-1'></i></span>
                <span className='crop'>{lang.crop.toUpperCase()}</span>
                <div className='d-flex flex-row gap-1 float-end pt-1'>
                  <div id='4' role='button' onClick={changeFilter} className={`anim variety all px-2 ${filter == 4 ?'filter':''}`} title={lang.all.toUpperCase()}>{lang.all.toUpperCase()}</div>
                  <div id='0' role='button' onClick={changeFilter} className={`anim variety green px-2 ${filter == 0 ?'filter':''}`} title={lang.types[0].toUpperCase()}>{lang.types[0].toUpperCase()[0]}</div>
                  <div id='1' role='button' onClick={changeFilter} className={`anim variety red px-2 ${filter == 1 ?'filter':''}`} title={lang.types[1].toUpperCase()}>{lang.types[1].toUpperCase()[0]}</div>
                  <div id='2' role='button' onClick={changeFilter} className={`anim variety garnet px-2 ${filter == 2 ?'filter':''}`} title={lang.types[2].toUpperCase()}>{lang.types[2].toUpperCase()[0]}</div>
                  <div id='3' role='button' onClick={changeFilter} className={`anim variety yellow px-2 ${filter == 3 ?'filter':''}`} title={lang.types[3].toUpperCase()}>{lang.types[3].toUpperCase()[0]}</div>
                </div>
              </div>
            </div>
            <div className='col-md-6 mb-3 text-start'>
              <button className='btn btn-warning small-shadow' data-bs-toggle='modal' data-bs-target='#tasksModal'>
                {lang.tasks}
                <span className="badge bg-danger ms-2 me-0">2</span>
              </button>
              <button className='btn btn-primary small-shadow ms-2' data-bs-toggle='modal' data-bs-target='#sectorModal'>
                {lang.list}
                <i className="bi bi-file-earmark-arrow-down ms-2"></i>
              </button>
            </div>
          </div>

          <div className='title'>{lang.green.toUpperCase()}: {data.greenhouses[green].name} - {filter == 4 ? lang.all : lang.types[filter]}</div>
          <div className='open-view mt-5'>
          {green == 0 &&
            <div className='green-a'>              
              <div>
                <div className={filter == 1 || filter == 4 ? 'red' : ''}></div>
                <div className={filter == 1 || filter == 4 ? 'red' : ''}></div>
                <div className='corridor'></div>
                <div></div>
                <div className={filter == 3 || filter == 4 ? 'yellow' : ''}></div>
              </div>
              <div>
                <div className={filter == 1 || filter == 4 ? 'red' : ''}></div>
                <div className={filter == 0 || filter == 4 ? 'green' : ''}></div>
                <div className='corridor'></div>
                <div className={filter == 3 || filter == 4 ? 'yellow' : ''}></div>
                <div className={filter == 3 || filter == 4 ? 'yellow' : ''}></div>
              </div>
              <div>
                <div className={filter == 1 || filter == 4 ? 'red' : ''}></div>
                <div className={filter == 1 || filter == 4 ? 'red' : ''}></div>
                <div className='corridor'></div>
                <div className={filter == 2 || filter == 4 ? 'garnet' : ''}></div>
                <div className={filter == 2 || filter == 4 ? 'garnet' : ''}></div>
              </div>
              <div>
                <div className={filter == 0 || filter == 4 ? 'green' : ''}></div>
                <div className={filter == 0 || filter == 4 ? 'green' : ''}></div>
                <div className='corridor'></div>
                <div></div>
                <div className={filter == 0 || filter == 4 ? 'green' : ''}></div>
              </div>
              <div>
                <div className={filter == 1 || filter == 4 ? 'red' : ''}></div>
                <div className={filter == 1 || filter == 4 ? 'red' : ''}></div>
                <div className='corridor'></div>
                <div></div>
                <div></div>
              </div>
              <div>
                <div></div>
                <div className={filter == 0 || filter == 4 ? 'green' : ''}></div>
                <div className='corridor'></div>
                <div className={filter == 0 || filter == 4 ? 'green' : ''}></div>
                <div className={filter == 0 || filter == 4 ? 'green' : ''}></div>
              </div>
              <div>
                <div className={filter == 0 || filter == 4 ? 'green' : ''}></div>
                <div className={filter == 0 || filter == 4 ? 'green' : ''}></div>
                <div className='corridor'></div>
                <div></div>
                <div></div>
              </div>
              <div>
                <div className={filter == 0 || filter == 4 ? 'green' : ''}></div>
                <div className={filter == 0 || filter == 4 ? 'green' : ''}></div>
                <div className='corridor'></div>
                <div></div>
                <div></div>
              </div>
              <div>
                <div className={filter == 0 || filter == 4 ? 'green' : ''}></div>
                <div className={filter == 0 || filter == 4 ? 'green' : ''}></div>
                <div className='corridor'></div>
                <div></div>
                <div className={filter == 0 || filter == 4 ? 'green' : ''}></div>
              </div>
              <aside className='sector-y'>
                <div>9</div>
                <div>8</div>
                <div>7</div>
                <div>6</div>
                <div>5</div>
                <div>4</div>
                <div>3</div>
                <div>2</div>
                <div>1</div>
              </aside>
              <div className='sector-x'>
                <div>A</div>
                <div>B</div>
                <div className='corridor'></div>
                <div>C</div>
                <div>D</div>
              </div>
            </div>
          }

          {green == 1 &&
            <div className='green-b'>
            <div>
              <div className={filter == 1 || filter == 4 ? 'red' : ''}></div>
              <div className={filter == 1 || filter == 4 ? 'red' : ''}></div>
              <div className='corridor'></div>
              <div></div>
              <div className={filter == 1 || filter == 4 ? 'red' : ''}></div>
            </div>
            <div>
              <div className={filter == 1 || filter == 4 ? 'red' : ''}></div>
              <div className={filter == 1 || filter == 4 ? 'red' : ''}></div>
              <div className='corridor'></div>
              <div></div>
              <div className={filter == 1 || filter == 4 ? 'red' : ''}></div>
            </div>            
            <div>
              <div className={filter == 1 || filter == 4 ? 'red' : ''}></div>
              <div className={filter == 1 || filter == 4 ? 'red' : ''}></div>
              <div className='corridor'></div>
              <div className={filter == 1 || filter == 4 ? 'red' : ''}></div>
              <div></div>
            </div>
            <div>
              <div className={filter == 2 || filter == 4 ? 'garnet' : ''}></div>
              <div></div>
              <div className='corridor'></div>
              <div className={filter == 1 || filter == 4 ? 'red' : ''}></div>
              <div className={filter == 1 || filter == 4 ? 'red' : ''}></div>
            </div>
            <div>
              <div className={filter == 2 || filter == 4 ? 'garnet' : ''}></div>
              <div className={filter == 2 || filter == 4 ? 'garnet' : ''}></div>
              <div className='corridor'></div>
              <div></div>
              <div className={filter == 1 || filter == 4 ? 'red' : ''}></div>
            </div>
            <div>
              <div></div>
              <div className={filter == 0 || filter == 4 ? 'green' : ''}></div>
              <div className='corridor'></div>
              <div className={filter == 0 || filter == 4 ? 'green' : ''}></div>
              <div className={filter == 2 || filter == 4 ? 'garnet' : ''}></div>
            </div>
            <div>
              <div className={filter == 0 || filter == 4 ? 'green' : ''}></div>
              <div className={filter == 0 || filter == 4 ? 'green' : ''}></div>
              <div className='corridor'></div>
              <div></div>
              <div className={filter == 0 || filter == 4 ? 'green' : ''}></div>
            </div>
            <div>
              <div className={filter == 0 || filter == 4 ? 'green' : ''}></div>
              <div className={filter == 0 || filter == 4 ? 'green' : ''}></div>
              <div className='corridor'></div>
              <div className={filter == 2 || filter == 4 ? 'garnet' : ''}></div>
              <div className={filter == 2 || filter == 4 ? 'garnet' : ''}></div>
              <div className='corridor'></div>
              <div className={filter == 3 || filter == 4 ? 'yellow' : ''}></div>
              <div className={filter == 3 || filter == 4 ? 'yellow' : ''}></div>
              <div></div>
            </div>
            <div>
              <div className={filter == 0 || filter == 4 ? 'green' : ''}></div>
              <div></div>
              <div className='corridor'></div>
              <div className={filter == 2 || filter == 4 ? 'garnet' : ''}></div>
              <div className={filter == 2 || filter == 4 ? 'garnet' : ''}></div>
              <div className='corridor'></div>
              <div></div>
              <div></div>
              <div className={filter == 3 || filter == 4 ? 'yellow' : ''}></div>
            </div>
            <div>
              <div className={filter == 1 || filter == 4 ? 'red' : ''}></div>
              <div className={filter == 1 || filter == 4 ? 'red' : ''}></div>
              <div className='corridor'></div>
              <div></div>
              <div className={filter == 2 || filter == 4 ? 'garnet' : ''}></div>
              <div className='corridor'></div>
              <div className={filter == 3 || filter == 4 ? 'yellow' : ''}></div>
              <div className={filter == 3 || filter == 4 ? 'yellow' : ''}></div>
              <div className={filter == 3 || filter == 4 ? 'yellow' : ''}></div>
            </div>
            <div>
              <div className={filter == 1 || filter == 4 ? 'red' : ''}></div>
              <div></div>
              <div className='corridor'></div>
              <div></div>
              <div></div>
              <div className='corridor'></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div>
              <div className={filter == 1 || filter == 4 ? 'red' : ''}></div>
              <div className={filter == 1 || filter == 4 ? 'red' : ''}></div>
              <div className='corridor'></div>
              <div className={filter == 1 || filter == 4 ? 'red' : ''}></div>
              <div className={filter == 1 || filter == 4 ? 'red' : ''}></div>
              <div className='corridor'></div>
              <div></div>
              <div className={filter == 3 || filter == 4 ? 'yellow' : ''}></div>
              <div className={filter == 3 || filter == 4 ? 'yellow' : ''}></div>
            </div>
            <aside className='sector-y'>
              <div>12</div>
              <div>11</div>
              <div>10</div>
              <div>9</div>
              <div>8</div>
              <div>7</div>
              <div>6</div>
              <div>5</div>
              <div>4</div>
              <div>3</div>
              <div>2</div>
              <div>1</div>
            </aside>
            <div className='sector-x'>
              <div>A</div>
              <div>B</div>
              <div className='corridor'></div>
              <div>C</div>
              <div>D</div>
              <div className='corridor'></div>
              <div>E</div>
              <div>F</div>
              <div>G</div>
            </div>
          </div>
          }

          {green == 2 &&
            <div className='green-c'>
            <div>
              <div></div>
              <div className={filter == 1 || filter == 4 ? 'red' : ''}></div>
              <div className={filter == 1 || filter == 4 ? 'red' : ''}></div>
              <div className='corridor'></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div>
              <div className={filter == 1 || filter == 4 ? 'red' : ''}></div>
              <div className={filter == 1 || filter == 4 ? 'red' : ''}></div>
              <div className={filter == 1 || filter == 4 ? 'red' : ''}></div>
              <div className='corridor'></div>
              <div className={filter == 3 || filter == 4 ? 'yellow' : ''}></div>
              <div className={filter == 3 || filter == 4 ? 'yellow' : ''}></div>
              <div></div>
            </div> 
            <div>
              <div></div>
              <div className={filter == 1 || filter == 4 ? 'red' : ''}></div>
              <div className={filter == 1 || filter == 4 ? 'red' : ''}></div>
              <div className='corridor'></div>
              <div></div>
              <div className={filter == 3 || filter == 4 ? 'yellow' : ''}></div>
              <div className={filter == 3 || filter == 4 ? 'yellow' : ''}></div>
            </div>
            <div>
              <div className={filter == 2 || filter == 4 ? 'garnet' : ''}></div>
              <div className={filter == 2 || filter == 4 ? 'garnet' : ''}></div>
              <div className={filter == 2 || filter == 4 ? 'garnet' : ''}></div>
              <div className='corridor'></div>
              <div className={filter == 3 || filter == 4 ? 'yellow' : ''}></div>
              <div className={filter == 3 || filter == 4 ? 'yellow' : ''}></div>
              <div className={filter == 3 || filter == 4 ? 'yellow' : ''}></div>
            </div>             
            <div>
              <div></div>
              <div className={filter == 1 || filter == 4 ? 'red' : ''}></div>
              <div className={filter == 1 || filter == 4 ? 'red' : ''}></div>
              <div className='corridor'></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div>
              <div className={filter == 2 || filter == 4 ? 'garnet' : ''}></div>
              <div className={filter == 2 || filter == 4 ? 'garnet' : ''}></div>
              <div className={filter == 2 || filter == 4 ? 'garnet' : ''}></div>
              <div className='corridor'></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div>
              <div></div>
              <div></div>
              <div></div>
              <div className='corridor'></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div>
              <div></div>
              <div className={filter == 2 || filter == 4 ? 'garnet' : ''}></div>
              <div className={filter == 2 || filter == 4 ? 'garnet' : ''}></div>
              <div className='corridor'></div>
              <div className={filter == 3 || filter == 4 ? 'yellow' : ''}></div>
              <div className={filter == 3 || filter == 4 ? 'yellow' : ''}></div>
              <div className={filter == 3 || filter == 4 ? 'yellow' : ''}></div>
            </div>
            <div>
              <div className={filter == 2 || filter == 4 ? 'garnet' : ''}></div>
              <div className={filter == 2 || filter == 4 ? 'garnet' : ''}></div>
              <div className={filter == 0 || filter == 4 ? 'green' : ''}></div>
              <div className='corridor'></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div>
              <div className={filter == 2 || filter == 4 ? 'garnet' : ''}></div>
              <div className={filter == 1 || filter == 4 ? 'red' : ''}></div>
              <div className={filter == 1 || filter == 4 ? 'red' : ''}></div>
              <div className='corridor'></div>
              <div className={filter == 3 || filter == 4 ? 'yellow' : ''}></div>
              <div className={filter == 3 || filter == 4 ? 'yellow' : ''}></div>
              <div></div>
            </div>
            <div>
              <div></div>
              <div></div>
              <div className={filter == 0 || filter == 4 ? 'green' : ''}></div>
              <div className='corridor'></div>
              <div className={filter == 3 || filter == 4 ? 'yellow' : ''}></div>
              <div className={filter == 3 || filter == 4 ? 'yellow' : ''}></div>
              <div className={filter == 3 || filter == 4 ? 'yellow' : ''}></div>
            </div>
            <div>
              <div className={filter == 0 || filter == 4 ? 'green' : ''}></div>
              <div className={filter == 0 || filter == 4 ? 'green' : ''}></div>
              <div className={filter == 0 || filter == 4 ? 'green' : ''}></div>
              <div className='corridor'></div>
              <div className={filter == 3 || filter == 4 ? 'yellow' : ''}></div>
              <div className={filter == 3 || filter == 4 ? 'yellow' : ''}></div>
              <div className={filter == 3 || filter == 4 ? 'yellow' : ''}></div>
            </div>
            <div>
              <div></div>
              <div></div>
              <div></div>
              <div className='corridor'></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div>
              <div></div>
              <div className={filter == 0 || filter == 4 ? 'green' : ''}></div>
              <div className={filter == 0 || filter == 4 ? 'green' : ''}></div>
              <div className='corridor'></div>
              <div className={filter == 3 || filter == 4 ? 'yellow' : ''}></div>
              <div className={filter == 3 || filter == 4 ? 'yellow' : ''}></div>
              <div className={filter == 3 || filter == 4 ? 'yellow' : ''}></div>
            </div>
            <aside className='sector-y'>
              <div>14</div>
              <div>13</div>
              <div>12</div>
              <div>11</div>
              <div>10</div>
              <div>9</div>
              <div>8</div>
              <div>7</div>
              <div>6</div>
              <div>5</div>
              <div>4</div>
              <div>3</div>
              <div>2</div>
              <div>1</div>
            </aside>
            <div className='sector-x'>
              <div>A</div>
              <div>B</div>
              <div>C</div>
              <div className='corridor'></div>
              <div>D</div>
              <div>E</div>
              <div>F</div>
            </div>
          </div>
          }
          </div>
        </div>
      }
      </div>
    </div>
  );
}

export default App;
